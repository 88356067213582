export const useCoreStore = defineStore("coreStore", () => {
  const api = useApi();
  const refreshMutex = ref<Promise<void>>();
  const vk = ref<string>("https://vk.com/bulldrop");
  const cdn = ref(
    `https://${isDev() || isReview() ? "dev.bulltrade.org" : "bulldrop.vip"}/cdn-cgi/image`,
  );
  const tg = ref<string>("https://t.me/bulldron");
  const tgSupportBot = ref<string>("https://t.me/BulldropSupportBot");
  const discord = ref<string>("https://discord.gg/bulldrop");
  const gamelight = ref("https://gamelight.ru");
  const gamelightPubg = ref("https://gamelight.ru/products/15");
  const gamelightCS2 = ref("https://gamelight.ru/products/steam");
  const imageSrcsetExceptions = ["DragonEgg.webp"];
  const percent = ref<number | null>(null);
  const topUpBalanceFormIsRequired = ref<boolean>(false);
  const withdrawFormIsRequired = ref<boolean>(false);
  const headerMenuIsRequired = ref<boolean>(false);
  const languageSubmenuIsRequired = ref<boolean>(false);
  const platformSwitcherIsRequired = ref<boolean>(false);
  const topUpInitialSum = ref<number>(101);
  const audioIsRequired = ref<boolean>(true);
  const intersectionObserverIsSupported = computed<boolean>(() => {
    if ("IntersectionObserver" in window) {
      return true;
    } else {
      return false;
    }
  });
  const mobile = computed<boolean>(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
  const gameStats = reactive({
    players: 0,
    casesOpened: 0,
    ladderBets: 0,
    crashBets: 0,
    minerBets: 0,
    wheelBets: 0,
  });
  let gameStatsFetched = false;

  async function getGameStats() {
    try {
      if (gameStatsFetched) return;
      const data = await api.games.overviewFooterValuesRetrieve();
      Object.assign(gameStats, data);
      gameStatsFetched = true;
    } catch (error) {
      /* empty */
    }
  }

  function toggleAudio() {
    audioIsRequired.value = !audioIsRequired.value;
    if (isClient()) {
      localStorage.setItem(
        "audio_is_required",
        JSON.stringify(audioIsRequired.value),
      );
    }
  }

  function showMenuHeader() {
    headerMenuIsRequired.value = true;
    languageSubmenuIsRequired.value = false;
  }

  function hideMenuHeader() {
    headerMenuIsRequired.value = false;
    document.getElementById("layout")!.style.removeProperty("overflow");
  }

  function toggleLanguageSubmenu() {
    languageSubmenuIsRequired.value = !languageSubmenuIsRequired.value;
  }

  function showLanguageSubmenu() {
    languageSubmenuIsRequired.value = true;
  }

  function hideLanguageSubmenu() {
    languageSubmenuIsRequired.value = false;
  }

  function showPlatformSwitcher() {
    platformSwitcherIsRequired.value = true;
  }

  function hidePlatfromSwitcher() {
    platformSwitcherIsRequired.value = false;
  }

  function togglePlatformSwitcher() {
    platformSwitcherIsRequired.value = !platformSwitcherIsRequired.value;
  }

  function showLanguageSubmenuMobile() {
    headerMenuIsRequired.value = false;
    languageSubmenuIsRequired.value = true;
  }

  function toggleMenuHeader() {
    headerMenuIsRequired.value = !headerMenuIsRequired.value;
    if (headerMenuIsRequired.value) {
      document.getElementById("layout")!.style.overflow = "hidden";
    } else {
      document.getElementById("layout")!.style.removeProperty("overflow");
    }
  }

  function showTopUpBalanceForm(initialSum: number = 0) {
    document.documentElement.style.overflow = "hidden";
    topUpBalanceFormIsRequired.value = true;
    topUpInitialSum.value = initialSum;
  }

  function hideTopUpBalanceForm() {
    topUpBalanceFormIsRequired.value = false;
    topUpInitialSum.value = 0;
    document.documentElement.style.overflow = "";
  }

  function showWithdrawForm() {
    document.documentElement.style.overflow = "hidden";
    withdrawFormIsRequired.value = true;
  }

  function hideWithdrawForm() {
    withdrawFormIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  return {
    refreshMutex,
    vk,
    cdn,
    tg,
    tgSupportBot,
    discord,
    gamelight,
    gamelightPubg,
    gamelightCS2,
    percent,
    topUpBalanceFormIsRequired,
    withdrawFormIsRequired,
    headerMenuIsRequired,
    platformSwitcherIsRequired,
    languageSubmenuIsRequired,
    topUpInitialSum,
    audioIsRequired,
    intersectionObserverIsSupported,
    mobile,
    imageSrcsetExceptions,
    gameStats,
    getGameStats,
    toggleAudio,
    showMenuHeader,
    hideMenuHeader,
    toggleLanguageSubmenu,
    showLanguageSubmenu,
    hideLanguageSubmenu,
    showPlatformSwitcher,
    hidePlatfromSwitcher,
    togglePlatformSwitcher,
    showLanguageSubmenuMobile,
    toggleMenuHeader,
    showTopUpBalanceForm,
    hideTopUpBalanceForm,
    showWithdrawForm,
    hideWithdrawForm,
  };
});
